// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-menu-menu-js": () => import("./../../../src/pages/menu/menu.js" /* webpackChunkName: "component---src-pages-menu-menu-js" */),
  "component---src-pages-openings-index-js": () => import("./../../../src/pages/openings/index.js" /* webpackChunkName: "component---src-pages-openings-index-js" */),
  "component---src-pages-refund-index-js": () => import("./../../../src/pages/refund/index.js" /* webpackChunkName: "component---src-pages-refund-index-js" */),
  "component---src-pages-refund-refunds-js": () => import("./../../../src/pages/refund/Refunds.js" /* webpackChunkName: "component---src-pages-refund-refunds-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-shop-js": () => import("./../../../src/pages/shop/shop.js" /* webpackChunkName: "component---src-pages-shop-shop-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simple-page.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

